import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl';
import { Helmet } from "react-helmet";
// import sal from 'sal.js/dist/sal.js';
// import 'sal.js/dist/sal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/main.scss';

import TriDymeIcon from './img/TriDymeIcon.png';

// Locale data
import enData from 'react-intl/locale-data/en';
import frData from 'react-intl/locale-data/fr';

// Messages
import en from '../../i18n/en.json';
import fr from '../../i18n/fr.json';

import NavBar from '../NavBar';
import Footer from '../Footer';
import Header from '../Header';
import HeadMeta from '../HeadMeta';

const messages = { en, fr }

addLocaleData([...enData, ...frData])

const Layout = ({ locale, children }) => {

  return(
    <div>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Fragment>
          <NavBar locale={locale} />
          <div className="containerBody">{children}</div>
          <Footer />
        </Fragment>
      </IntlProvider>
    </div>
  )
}

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default Layout
