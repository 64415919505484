import React, { Component } from 'react';
import {
  Container, Col, Row,
  Nav, NavItem, NavLink,
  Label
} from 'reactstrap';
import { Link } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import locales from '../../constants/locales';
import Language from '../Language';
import LocalizedLink from '../LocalizedLink';
import FaceBookSvg from './img/facebook.svg';
import TwitterSvg from './img/twitter.svg';
import YoutubeSvg from './img/youtube.svg';
import LinkedinSvg from './img/linkedin.svg';
import SubscribeNewsletter from '../SubscribeNewsletter';

class Footer extends Component {
  render() {
    const actualYear = new Date(Date.now()).getFullYear();
    const { intl: { locale } } = this.props;
    const footerMenu = {
      left: [
        {
          id: "footer.home",
          defaultMessage: "Home",
          to: "/"
        },
        {
          id: "footer.blog",
          defaultMessage: "Blog",
          to: "/blog/"
        },
        {
          id: "footer.documentation",
          defaultMessage: "Documentation",
          to: `/documentation/${locale}/presentation/introduction/`
        },
        {
          id: "footer.projects",
          defaultMessage: "Projects",
          to: `/projects/`
        }

      ],
      middle: [
        {
          id: "footer.webapps",
          defaultMessage: "Web Applications",
          to: "/webapps/"
        },
        {
          id: "footer.training",
          defaultMessage: "Formations",
          to: "/formation/"
        },
        {
          id: "footer.dev-services",
          defaultMessage: "Development",
          to: null,
          href: "https://www.tridyve.com"
        },
        {
          id: "footer.engineering-services",
          defaultMessage: "Engineering",
          to: null,
          href: "https://tridyme.notion.site/TriDyme-Bureau-d-tudes-structure-48a076865c114c5bb6dca32bf9df3f4c?pvs=4"
        },
        // {
        //   id: "footer.engineering",
        //   defaultMessage: "Engineering",
        //   to: "/career/"
        // },
        // {
        //   id: "footer.about",
        //   defaultMessage: "About",
        //   to: `/about/${locale}-about`
        // },
      ],
      right: [
        {
          id: "footer.contact",
          defaultMessage: "Contact",
          to: "/contact/"
        },
        {
          id: "footer.career",
          defaultMessage: "Careers",
          to: "/career/"
        },
        {
          id: "footer.termsAndConditions",
          defaultMessage: "Terms & Conditions",
          to: `/legal/${locale}-termsandconditions`
        },
        {
          id: "footer.privacyPolicy",
          defaultMessage: "PrivacyPolicy",
          to: `/legal/${locale}-privacypolicy`
        },
      ]
    }
    return (
      <footer className="footer">
        <Container className="footerContainer">
          <Row>
            <Col lg={8}>
              <Label className="subtitle" style={{ color: 'white' }}>
                <FormattedMessage
                  id="footer.connect"
                  defaultMessage="Connect to TriAzur:"
                />
              </Label>
              <Nav>
                <NavItem>
                  <a aria-label="facebookLink" href="https://www.facebook.com/TriDyme" target="_blank">
                    <img id="logo-app" className="img-responsive" src={FaceBookSvg} alt="" />
                  </a>
                </NavItem>
                <NavItem>
                  <a aria-label="twitterLink" href="https://twitter.com/_TriDyme" target="_blank">
                    <img id="logo-app" className="img-responsive" src={TwitterSvg} alt="" />
                  </a>
                </NavItem>
                <NavItem>
                  <a aria-label="youtubeLink" href="https://www.youtube.com/channel/UCOQy5Sw3t9IEFxzYJZG0OWA" className="fa fa-youtube" target="_blank">
                    <img id="logo-app" className="img-responsive" src={YoutubeSvg} alt="" />
                  </a>
                </NavItem>
                <NavItem>
                  <a aria-label="linkedinLink" href="https://www.linkedin.com/company/27166752/" className="fa fa-linkedin" target="_blank">
                    <img id="logo-app" className="img-responsive" src={LinkedinSvg} alt="" />
                  </a>
                </NavItem>
              </Nav>
              <hr />
            </Col>
            <Col lg={4}>
              {/* <SubscribeNewsletter /> */}
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Nav vertical>
                {
                  footerMenu.left.map(item => (
                    <NavItem className="footernavitem">
                      {(item.to == null) ?
                        <a
                          className="footerlink"
                          href={item.href}
                        >
                          <FormattedMessage
                            id={item.id}
                            defaultMessage={item.defaultMessage}
                          />
                        </a>
                        :
                        <LocalizedLink
                          className="footerlink"
                          to={item.to}
                        >
                          <FormattedMessage
                            id={item.id}
                            defaultMessage={item.defaultMessage}
                          />
                        </LocalizedLink>
                      }
                    </NavItem>
                  ))
                }
              </Nav>
            </Col>
            <Col md={4}>
              <Nav vertical className="middle-menu">
                {
                  footerMenu.middle.map(item => (
                    <NavItem className="footernavitem">
                      <LocalizedLink
                        className="footerlink"
                        to={item.to}
                      >
                        <FormattedMessage
                          id={item.id}
                          defaultMessage={item.defaultMessage}
                        />
                      </LocalizedLink>
                    </NavItem>
                  ))
                }
              </Nav>
            </Col>
            <Col md={4} className="right-menu">
              <Nav vertical>
                {
                  footerMenu.right.map(item => (
                    <NavItem className="footernavitem">
                      <LocalizedLink
                        className="footerlink"
                        to={item.to}
                      >
                        <FormattedMessage
                          id={item.id}
                          defaultMessage={item.defaultMessage}
                        />
                      </LocalizedLink>
                    </NavItem>
                  ))
                }
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <br />
              <p className="copyright text-muted small">Copyright &copy; {`TriDyme ${actualYear}.`}<FormattedMessage id="rights" /></p>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

Footer.propTypes = { intl: intlShape.isRequired };

export default injectIntl(Footer);
