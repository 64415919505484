import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  Button,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import LocalizedLink from '../LocalizedLink';
import locales from '../../constants/locales';
import Language from '../Language';
import TriDymeIcon from './img/TriDymeIcon.png';
import SignUpSvg from './img/SignUp.svg';
import SignInSvg from './img/SignIn.svg';
import AskForDemoButton from '../Button/AskForDemo';
import TriDymeSvg from './img/TriDymeV1.svg';
import Covid19Svg from './img/warning.svg';
const {
  REACT_APP_URL
} = process.env;

const visibility = false;

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      dropDownLanguageValue: 'En'
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { intl: { locale } } = this.props;
    const linkToApp = "https://app.tridyme.com";
    return (
      <div>
        <Navbar className="navbarapp" dark expand="lg" fixed="top">
          <NavbarBrand
            tag={LocalizedLink}
            to='/'
            id="navbarTitle1"
          // style={{ backgroundImage: `url(${TriDymeIcon})` }}
          >
            <img src={TriDymeSvg} />
            {/* TriDyme */}
          </NavbarBrand>
          <NavbarToggler aria-label="toggler" onClick={this.toggle} style={{ color: 'white' }}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {/* <NavItem id="navbarTitle2">
                  <a className="nav-link"  href="https://www.triagile.fr" target="_blank">
                    <img className="img-covid19" src={Covid19Svg } /> Covid-19
                  </a>
                </NavItem> */}
              <NavItem id="navbarTitle2">
                <UncontrolledDropdown nav inNavbar className=".dropdownmenuapp">
                  <DropdownToggle nav caret>
                    <FormattedMessage
                      id="navbar.youare"
                      defaultMessage="Your Are ?"
                    />
                  </DropdownToggle>
                  <DropdownMenu left className="dropdownmenu">
                    <DropdownItem
                      tag={LocalizedLink}
                      to="/contractor/"
                      className="dropdownitem"
                    >
                      <FormattedMessage
                        id="navbar.youare.moa"
                        defaultMessage="Contractor"
                      />
                    </DropdownItem>
                    <DropdownItem
                      tag={LocalizedLink}
                      to="/architect/"
                      className="dropdownitem"
                    >
                      <FormattedMessage
                        id="navbar.youare.architect"
                        defaultMessage="Architect"
                      />
                    </DropdownItem>
                    {visibility &&
                      <>
                        <DropdownItem
                          tag={LocalizedLink}
                          to="/engineer/"
                          className="dropdownitem"
                        >
                          <FormattedMessage
                            id="navbar.youare.engineer"
                            defaultMessage="Engineer"
                          />
                        </DropdownItem>
                        <DropdownItem
                          tag={LocalizedLink}
                          to="/university/"
                          className="dropdownitem"
                        >
                          <FormattedMessage
                            id="navbar.youare.school"
                            defaultMessage="University"
                          />
                        </DropdownItem>
                        <DropdownItem
                          tag={LocalizedLink}
                          to="/constructionCompany/"
                          className="dropdownitem"
                        >
                          <FormattedMessage
                            id="navbar.youare.company"
                            defaultMessage="Construction Company"
                          />
                        </DropdownItem>
                      </>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              <NavItem id="navbarTitle2">
                <UncontrolledDropdown nav inNavbar className=".dropdownmenuapp">
                  <DropdownToggle nav caret>
                    <FormattedMessage
                      id="navbar.products"
                      defaultMessage="Our Products"
                    />
                  </DropdownToggle>
                  <DropdownMenu left className="dropdownmenu">
                    <DropdownItem
                      className="dropdownitem"
                      tag={LocalizedLink}
                      to="/bim-developer/"
                      // href="https://tribim.tridyme.com"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="navbar.bim"
                        defaultMessage="BIM"
                      />
                    </DropdownItem>
                    {visibility &&
                      <DropdownItem
                        tag={LocalizedLink}
                        to="/webapps/"
                        className="dropdownitem"
                      >
                        <FormattedMessage
                          id="navbar.webapps"
                          defaultMessage="Web Applications"
                        />
                      </DropdownItem>
                    }
                    {/* <DropdownItem
                      tag={LocalizedLink}
                      to="/plugins/"
                      className="dropdownitem"
                    >
                      <FormattedMessage
                        id="navbar.plugins"
                        defaultMessage="Plugins"
                      />
                    </DropdownItem> */}
                    {locale === "fr" &&
                      <DropdownItem
                        tag={LocalizedLink}
                        to={`/formation/`}
                        className="dropdownitem"
                      >
                        <FormattedMessage
                          id="navbar.training"
                          defaultMessage="Training"
                        />
                      </DropdownItem>
                    }
                    {/* <DropdownItem
                      tag={LocalizedLink}
                      to="/plugins/"
                      className="dropdownitem"
                    >
                      <FormattedMessage
                        id="navbar.plugins"
                        defaultMessage="Plugins"
                      />
                    </DropdownItem> */}
                    <DropdownItem
                      className="dropdownitem"
                      href="https://www.tridyve.com"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="navbar.dev"
                        defaultMessage="Development"
                      />
                    </DropdownItem>
                    <DropdownItem
                      className="dropdownitem"
                      href="https://tridyme.notion.site/TriDyme-Bureau-d-tudes-structure-48a076865c114c5bb6dca32bf9df3f4c?pvs=4"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="navbar.engineering"
                        defaultMessage="Engineering"
                      />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              {/* <NavItem id="navbarTitle2">
                  <LocalizedLink className="nav-link" to="/projects/">
                    <FormattedMessage 
                      id="navbar.projects"
                      defaultMessage="Projects"
                    />
                  </LocalizedLink>
                </NavItem> */}
              {/* {locale === "fr" &&
                <NavItem id="navbarTitle2">
                  <LocalizedLink className="nav-link" to={`/formation/`} >
                    Formations
                    </LocalizedLink>
                </NavItem>
              } */}
              {/* <NavItem id="navbarTitle2">
                <LocalizedLink className="nav-link" to="/projects/" >
                  <FormattedMessage
                    id="navbar.projects"
                    defaultMessage="Projects"
                  />
                </LocalizedLink>
              </NavItem> */}
              {visibility &&
                <NavItem id="navbarTitle2">
                  <LocalizedLink className="nav-link" to={`/documentation/${locale}/presentation/introduction/`} >
                    Documentation
                  </LocalizedLink>
                </NavItem>
              }
              <NavItem id="navbarTitle2">
                <LocalizedLink className="nav-link" to="/career/" >
                  <FormattedMessage
                    id="navbar.career"
                    defaultMessage="Careers"
                  />
                </LocalizedLink>
              </NavItem>
              <NavItem id="navbarTitle2">
                <LocalizedLink className="nav-link" to="/blog/">
                  Blog
                </LocalizedLink>
              </NavItem>
              <NavItem id="navbarTitle2">
                <LocalizedLink className="nav-link" to="/contact/" >
                  Contact
                </LocalizedLink>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <Language
                dropDownLanguageValue={locales[locale].locale}
              />
              {/* <NavItem className="nav-item">
                <a className="nav-link" href={`${linkToApp}/signin`} id="navbarTitle3">
                  <img alt="Sign In" src={SignInSvg} className="material-icons d-inline-block align-top" />
                  <FormattedMessage id="signIn" />
                </a>
              </NavItem> */}
              <NavItem className="nav-item">
                {/* <AskForDemoButton
                  id="landingPage.presentation.button.demo"
                  defaultMessage="Ask for a demo"
                  className="positiveButton"
                /> */}
                <LocalizedLink to="/contact/">
                  <Button
                    className="positiveButton"
                  >
                    {`Demande de démo`}
                  </Button>
                </LocalizedLink>
                {/* <Button
                  href={`${linkToApp}/signup`}
                  className="positiveButton"
                >
                  <img alt="Sign Up" src={SignUpSvg} className="material-icons d-inline-block align-top" />
                  <FormattedMessage id="signUp" />
                </Button> */}
                {/* <a className="nav-link" href="http://localhost:3000/signup" id="navbarTitle3">
                    <i className="material-icons d-inline-block align-top">exit_to_app</i> 
                    <FormattedMessage id="signUp" />
                  </a> */}
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

NavBar.propTypes = { intl: intlShape.isRequired }

export default injectIntl(NavBar);
