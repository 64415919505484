module.exports = {
  en: {
    path: 'en',
    locale: 'En',
    default: true
  },
  fr: {
    path: 'fr',
    locale: 'Fr',
  }
}