import React, { Component } from 'react';
import {
    Container, Col, Row,
    Nav, NavItem, NavLink,
    Form, FormGroup, Label, Input, Button,
    InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import LocalizedLink from '../LocalizedLink';
// import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class SubscribeNewsletter extends Component{
  // constructor(props) {
  //   super(props);
  //   this._handleSubmit = this._handleSubmit.bind(this);
  //   this.state = {
  //       fromMailchimp: {
  //         msg: '',
  //         result: ''
  //       },
  //       emailValue: '',
  //   };
  // }
  // _handleSubmit = async (email) => {
  //   const connection = await addToMailchimp(email);
  //   this.setState({ fromMailchimp: {
  //       msg: connection.msg,
  //       result: connection.result
  //     },
  //     emailValue: ''
  //   });
  // }
  render(){
    return(
      <div className="subscribe-newsletter">
        {/* <Label
          className="subtitle" 
          style={{color:'white'}}
        >
          <FormattedMessage
            id="footer.subscribe"
            defaultMessage="Subscribe to the Newsletter:"
          />
        </Label>
        <Form 
          className="subscribe-newsletter-form"
          onSubmit={(e) => {
            e.preventDefault();
            this._handleSubmit(this.state.emailValue);
          }}
          inline
        >
          <FormGroup>
            <Input
              type="email"
              name="email"
              value={this.state.emailValue}
              onChange={(e)=>{
                this.setState({emailValue: e.target.value});
              }} 
              id="exampleEmail"
              placeholder="Email"
              required
            />
            <InputGroupAddon addonType="append">
              <Button
                type="submit"
                className="positiveButton"
              >
                <FormattedMessage
                  id="footer.submit"
                  defaultMessage="Submit"
                />
              </Button>
            </InputGroupAddon>
          </FormGroup>
          <Label
          className="subtitle" 
          style={{color: this.state.fromMailchimp.result === 'success' ? 'green' : 'red' }}
          >
            {this.state.fromMailchimp.result === 'success' ? this.state.fromMailchimp.msg : ''}
          </Label>
        </Form> */}
      </div>
    )
  }
}