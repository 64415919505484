import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import * as typeformEmbed from '@typeform/embed';
import { ReactTypeformEmbed } from 'react-typeform-embed';

export default class AskForDemoButton extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.typeformPopup = this.typeformPopup.bind(this);
  }

  componentDidMount() {
    this.popup = typeformEmbed.makePopup(
      'https://triazur.typeform.com/to/rstolJ',
      {
        mode: 'popup',
        //autoOpen: true,
        autoClose: 3,
        hideHeaders: true,
        hideFooters: true,
        hideScrollbars: true,
        onSubmit: function () {
          console.log('Typeform successfully submitted')
        }
      }
    )
  }

  typeformPopup() {
    this.popup.open();
  }


  render() {
    const {
      id,
      defaultMessage,
      className
    } = this.props;
    return (
      <Button
        //href={link}
        className={className}
        onClick={() => {
          this.typeformPopup();
        }}
      >
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
        />
      </Button>
    )
  }
}