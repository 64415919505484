import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Label,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'gatsby';

import locales from '../../constants/locales'
import frIcon from './img/fr.svg';
import gbIcon from './img/gb.svg';
import usIcon from './img/us.svg';

const Language = ({dropDownLanguageValue, changeLanguageValue}) => {
  const localesflag = (locale) => {
    switch (locale) {
      case 'En':
        return gbIcon;
      case 'US':
        return usIcon;
      case 'Fr':
        return frIcon;
      default:
        return frIcon;
    }
  };

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret id="navbarTitle3">
      <img className="flagimg" src={localesflag(dropDownLanguageValue)} alt="" width="25" height="25" /> 
      {/* {dropDownLanguageValue} */}
      </DropdownToggle>
      <DropdownMenu right className="languagedropdownmenu">
        {
          Object.keys(locales).map(key => (
            <DropdownItem 
              className="languagedropdownitem" 
              key={locales[key].locale} 
              tag={ Link }
              to={locales[key].default ? '/' : `/${locales[key].path}`}
            >
              <FormGroup row>
                  <Col xs={4} style={{ maxWidth: '40px' }}>
                    <img className="flagimg" src={localesflag(locales[key].locale)} alt={locales[key].locale} width="25" height="25" />
                  </Col>
                  <Label xs={8} className="languagelocale">
                      {locales[key].locale}
                  </Label>
              </FormGroup>
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Language;